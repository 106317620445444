import { FC, useCallback, useRef, PropsWithChildren, ComponentProps } from 'react';
import classnames from 'classnames';

import { Breakpoint, GridRow, useBreakpoint, useResize } from '@hh.ru/magritte-ui';
import { Breakpoint as BlokoBreakpoint, getBreakpoint as getBlokoBreakpoint } from 'bloko/common/media';

import useScroll from 'src/hooks/useScroll';

import styles from './sticky-sidebar.less';

// Режимы работы липкого блока
enum StickySidebarMode {
    // Без изменений
    Static = 'Static',
    // Липкий блок фиксируется и скроллится вместе с контентом.
    Scroll = 'Scroll',
    // Липкий блок прилипает верхним краем при прокрутке вниз.
    StickyTop = 'StickyTop',
    // Липкий блок прилипает нижним краем при прокрутке вверх.
    StickyBottom = 'StickyBottom',
}

const ClassByMode = {
    [StickySidebarMode.Static]: styles.stickySidebarStatic,
    [StickySidebarMode.Scroll]: styles.stickySidebarScroll,
    [StickySidebarMode.StickyTop]: styles.stickySidebarTop,
    [StickySidebarMode.StickyBottom]: styles.stickySidebarBottom,
};

export const SCREEN_XS = [Breakpoint.XS];
export const SCREEN_XS_S = [Breakpoint.XS, Breakpoint.S];
export const SCREEN_XS_S_M = [Breakpoint.XS, Breakpoint.S, Breakpoint.M];

export const BLOKO_SCREEN_XS = [BlokoBreakpoint.XS];
export const BLOKO_SCREEN_XS_S = [BlokoBreakpoint.XS, BlokoBreakpoint.S];
export const BLOKO_SCREEN_XS_S_M = [BlokoBreakpoint.XS, BlokoBreakpoint.S, BlokoBreakpoint.M];

/**
 * Обёртка для StickySidebar и контента.
 *
 * Дочерние элементы становятся одинаковыми по высоте, за счёт чего
 * прилипающий блок может двигаться по вертикали (если контент больше
 * липкого блока).
 *
 * Контент — это соседний со StickySidebar элемент. В качестве
 * контента может выступать любой элемент, не обязательно колонка.
 *
 * Пример разметки с колонками:
    // Обёртка
    <StickySidebarAndContent>
        // Сайдбар
        <StickySidebar disabledScreens={SCREEN_XS_S}>
            <GridColumn xs="0" s="0" m="4" l="4">{sidebarContent}</Column>
        </StickySidebar>
        // Контент
        <GridColumn xs="4" s="8" m="8" l="12">{pageContent}</Column>
    </StickySidebarAndContent>
 */
export const StickySidebarAndContent: FC<{ isGridContent?: boolean } & ComponentProps<typeof GridRow>> = ({
    children,
    // tempexp_29658_magritte_resume_serp_start
    isGridContent,
    ...props
    // tempexp_29658_magritte_resume_serp_end
}) => {
    // tempexp_29658_magritte_resume_next_line
    if (!isGridContent) {
        return <div className={styles.stickySidebarAndContent}>{children}</div>;
    }
    // tempexp_29658_magritte_resume_serp_start
    return (
        <GridRow {...props}>
            <div className={classnames(styles.stickySidebarAndContent, styles.stickySidebarAndContentGrid)}>
                {children}
            </div>
        </GridRow>
    );
    // tempexp_29658_magritte_resume_serp_end
};

/**
 * Липкий блок с кастомной логикой прилипания:
 * - Если липкий блок меньше, чем размер вьюпорта, прилипает
 *   верхний край (полный аналог `position: sticky; top: 0;`).
 * - Если липкий блок больше вьюпорта, то он листается вместе с контентом.
 * -- Если при скролле вниз дошли до нижней границы липкого блока,
 *    прилипает нижний край. При скролле отсюда вверх липкий блок снова
 *    будет проматываться, чтобы можно было увидеть всё его содержимое.
 * -- Если при скролле вверх дошли до верхней границы липкого блока,
 *    прилипает верхний край. При скролле отсюда вниз липкий блок снова
 *    будет проматываться, чтобы можно было увидеть всё его содержимое.
 */
const StickySidebar: FC<
    {
        disabledScreens?: Breakpoint[] | BlokoBreakpoint[];
    } & PropsWithChildren
> = ({ children, disabledScreens }) => {
    const stickyRef = useRef<HTMLDivElement>();
    const scrollPosition = useRef(typeof window !== 'undefined' ? window.scrollY : 0);
    const trackScrolling = useRef(false);
    const mode = useRef(StickySidebarMode.Static);

    const setMode = useCallback((newMode: StickySidebarMode) => {
        if (stickyRef.current && mode.current !== newMode) {
            stickyRef.current.style.top =
                newMode === StickySidebarMode.Scroll ? `${stickyRef.current.offsetTop}px` : '';
            stickyRef.current.className = ClassByMode[newMode];
        }
        mode.current = newMode;
    }, []);

    const scrollHandler = useCallback(() => {
        if (!trackScrolling.current || !stickyRef.current) {
            return;
        }

        const isMovingUp = scrollPosition.current > window.scrollY;
        const isMovingDown = scrollPosition.current < window.scrollY;
        const sticky = stickyRef.current.getBoundingClientRect();

        if (isMovingUp && Math.ceil(sticky.top) >= 0) {
            // Мотаем вверх, и верхний край липкого блока уже на экране, значит он
            // больше не должен ехать вниз вместе со скроллом, прилепляем верхний край.
            // ЛИБО мотаем вверх НАД контентом, где липкий блок ещё не прилипает.
            setMode(StickySidebarMode.StickyTop);
        } else if (isMovingDown && Math.floor(sticky.bottom) <= window.innerHeight) {
            // Мотаем вниз, и нижний край липкого блока уже на экране, значит он
            // больше не должен ехать вверх вместе со скроллом, прилепляем нижний край.
            // ЛИБО мотаем вниз ПОД контентом, где липкий блок уже не прилипает.
            setMode(StickySidebarMode.StickyBottom);
        } else if (isMovingUp || isMovingDown) {
            // Мотаем в сторону края, который ЗА пределами экрана,
            // значит нужно включить прокрутку, чтобы была возможность
            // увидеть всё содержимое липкого блока.
            setMode(StickySidebarMode.Scroll);
        }

        scrollPosition.current = window.scrollY;
    }, [setMode]);

    const { breakpoint } = useBreakpoint();

    // Вызывается при изменении конфигурации блоков: ресайз окна, ресайз
    // липкого блока, ресайз контента, обновление рефа липкого блока.
    const setupSticky = useCallback(() => {
        // По дефолту слежение за скроллом ВЫКЛючено.
        trackScrolling.current = false;

        if (!stickyRef.current || process.env.SSR) {
            return;
        }

        const blokoBreakpoint = getBlokoBreakpoint();
        if (disabledScreens?.includes(breakpoint) || disabledScreens?.includes(blokoBreakpoint)) {
            setMode(StickySidebarMode.Static);
            return;
        }

        const sticky = stickyRef.current.getBoundingClientRect();
        const parentHeight = (stickyRef.current.parentNode as Element)?.getBoundingClientRect().height || 0;

        // Если липкий блок больше контента, ездить он точно не будет.
        if (sticky.height >= parentHeight) {
            setMode(StickySidebarMode.Static);
            return;
        }

        // Если липкий блок меньше высоты окна,
        // это обычный `position: sticky; top: 0`.
        if (sticky.height <= window.innerHeight) {
            setMode(StickySidebarMode.StickyTop);
            return;
        }

        // Липкий блок больше вьюпорта (раз мы тут),
        // начинаем следить за скроллом.
        trackScrolling.current = true;

        // Логика определения начального режима такая же,
        // как при скролле, только без учёта направлений.
        if (sticky.top >= 0) {
            setMode(StickySidebarMode.StickyTop);
        } else if (sticky.bottom <= window.innerHeight) {
            setMode(StickySidebarMode.StickyBottom);
        } else {
            setMode(StickySidebarMode.Scroll);
        }
    }, [breakpoint, disabledScreens, setMode]);

    useResize(setupSticky);
    useScroll(scrollHandler);

    const resizeObserver = useRef<ResizeObserver>();

    const stickyRefCallback = useCallback(
        (ref: HTMLDivElement) => {
            if (!resizeObserver.current && typeof ResizeObserver !== 'undefined') {
                resizeObserver.current = new ResizeObserver(setupSticky);
            }

            if (resizeObserver.current) {
                // Если меняется ссылка, нужно отписаться от предыдущих элементов.
                stickyRef.current && resizeObserver.current.unobserve(stickyRef.current);
                stickyRef.current && resizeObserver.current.unobserve(stickyRef.current.parentNode as Element);

                // Делаем перерасчёт при изменении размеров липкого блока
                // или его враппера (высота враппера == высоте контента).
                ref && resizeObserver.current.observe(ref);
                ref && resizeObserver.current.observe(ref.parentNode as Element);
            }

            stickyRef.current = ref;
            setupSticky();
        },
        [setupSticky]
    );

    return (
        <div className={styles.stickySidebarWrapper}>
            <div ref={stickyRefCallback}>{children}</div>
            <div className={styles.stickySidebarHelper} />
        </div>
    );
};

export default StickySidebar;
